import React from 'react';
import twemoji from 'twemoji';
import styled from 'styled-components';

import Container from '../components/containers/Container';
import { ButtonLink } from '../components/actions/Button';

const NotFoundPage = () => (
  <CustomContainer>
    <div>
      <div className="emojiContainer" dangerouslySetInnerHTML={{ __html: twemoji.parse('😏') }} />
      <h2>Ops! Parece que está página não existe.</h2>
      <p>Não se preocupe, todos nós nos perdemos de vez em quando.</p>

      <ButtonLink to="/">Voltar para o ínicio</ButtonLink>
    </div>
  </CustomContainer>
);

const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 580px;
  height: 100vh;
`;

export default NotFoundPage;

import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const ButtonStyle = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  display: inline-block;
  cursor: pointer;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 6px 16px;
  border-radius: 0.4rem;
  text-decoration: none;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.2s ease-in-out;

  :hover {
    border-color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, 1);  
  }
`;

const ButtonStyleDefault = css`
  border: 1px solid #8c54ea;
  color: #8c54ea;

  :hover, :focus {
    background: #8c54ea;
    border-color: #8c54ea;
    color: #FFF;  
  }
`;

const Button = styled.a`
  ${ButtonStyle}
  ${ButtonStyleDefault}
`;

const ButtonLink = styled(Link)`
  ${ButtonStyle}
  ${ButtonStyleDefault}
`;

export default Button;
export { ButtonStyle, ButtonLink };

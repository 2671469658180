import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem;
  padding-top: 0;

  @media (max-width : 768px) {
    padding: 0px 0.6rem;
  }
`;

export default Container;
